.homepage #mainContent-wrapper {
  padding-top: 220px;
}
#mainContent-wrapper {
  padding-top: 260px;
}
h1,
.mainHeadline {
  color: #D42048;
}
h1 a,
.mainHeadline a {
  color: #D42048;
}
h1 a:hover,
.mainHeadline a:hover {
  color: #D42048;
  text-decoration: none;
}
.subHeadline {
  color: #D42048;
}
.subHeadlineNoDots {
  color: #D42048;
}
.breadcrumb-container {
  background-color: #FFD8DB;
}
#flyout {
  background-color: #D42048;
}
#footer-wrapper::before {
  background-color: #FFD8DB;
}
#main-nav ul > li > a:hover {
  color: #D42048;
}
#main-nav ul > li.active > a.active {
  color: #D42048;
}
@media all and (min-width: 992px) {
  .navbar li.show > a {
    color: #D42048 !important;
  }
  .navbar .megamenu .sectionLink a:hover {
    color: #D42048;
  }
}
.btn-primary,
.btn-primary-no-icon {
  background-color: #D42048;
  color: #FFFFFF !important;
  font-size: 1rem !important;
  padding: 0.375rem 0.75rem;
}
.btn-primary:hover,
.btn-primary-no-icon:hover {
  background-color: #D42048;
  color: #EFEFEF;
}
.btn-more {
  background-color: #D42048;
  color: #FFFFFF;
}
.btn-back {
  background-color: #D42048;
  color: #FFFFFF;
}
.btn-share {
  background-color: #D42048;
  color: #FFFFFF;
}
.linkBox,
.linkBoxArrow,
.linkBoxArrowAbs {
  background-color: #D42048;
}
.linkWithSVG {
  color: #D42048;
}
.linkWithSVG svg {
  fill: #D42048;
}
.carousel .carousel-item .slideritem-titel {
  color: #D42048 !important;
}
.carousel .carousel-control-prev,
.carousel .carousel-control-next {
  background-color: #D42048;
}
.adBox,
.adBox__Shadow {
  background-color: #D42048;
}
section.whiteBoxShadow .newsblock-titel a {
  color: #D42048;
}
section.whiteBoxShadow .newsblock-content {
  font-family: "foco";
  font-weight: 300;
  font-size: 1rem;
  color: #1A171B;
  line-height: 1.4rem;
}
section.whiteBoxShadow .newsblock-link {
  font-family: "foco";
  font-weight: 400;
  font-style: italic;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 1px;
}
.article-box-small h3 a {
  color: #D42048;
}
.article-box-small .moreLink {
  color: #D42048;
}
.article-box-small .moreLink a {
  color: inherit;
}
.articleDetail h1 {
  color: #D42048;
}
.articleDetail .icdcodes {
  color: #D42048;
}
@supports not (backdrop-filter: url('#colorize')) {
  .articleDetail .topArticleImage figcaption::before {
    background-color: #D42048;
  }
}
.companyList-row .redBoxWithArrow {
  background-color: #FFD8DB;
}
.companyList-row:hover .redBoxWithArrow {
  background-color: #D42048;
}
.paginationWrapper .pagination li.active {
  color: #D42048;
}
.paginationWrapper .pagination li.active a.active {
  color: #D42048;
}
.paginationWrapper .pagination li a:hover,
.paginationWrapper .pagination li a:focus {
  color: #D42048;
}
.ausgaben-card .card-title {
  color: #D42048;
}
.ausgaben-card .linkToDocument,
.ausgaben-card .linkToLogin,
.ausgaben-card .linkToMore {
  background-color: #D42048;
}
.accordion .card-header {
  background-color: #D42048;
}
.registerFromWrapper h2 {
  color: #D42048;
}
.registerFromWrapper #progressbar li.active:before,
.registerFromWrapper #progressbar li.active:after {
  background: #D42048;
}
